import React from 'react'
import TitleMain from './titleMain';
import Button from './button';
import store from '../store/orderStore.js';
//Router
import {Link } from 'react-router-dom';
//config
import  config from '../config';


class OrderState extends React.Component {
  componentWillUnmount(){
    store.order = new Map();
  }
  render () {
    return(
      <div>
        <TitleMain name='訂單狀態'/>
        <div className="OrderState">
          <div className="OrderState__content" style={{margin:'50px auto'}}>
            <h2>下單成功</h2>
            <h2>報價單已發送至您的郵箱</h2>
          </div>
          <a href={`${config.server}/invoice/${this.props.store.info[this.props.store.info.length - 2]}/${this.props.store.info[this.props.store.info.length - 1]}`} target='_blank'>
            <Button name='下載報價單' style={{margin:'25px 50px',fontSize:'20px'}}/>
          </a>
          <Link to='/orderQuery'>
            <Button name='查看訂單' style={{margin:'25px 50px',fontSize:'20px'}}/>
          </Link>
        </div>
      </div>
    )
  }
}

export default OrderState;
