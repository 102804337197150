import React ,{Component} from 'react';

const comingSoonStyle={
  margin:'150px auto',
  fontSize:48,
};

class ComingSoon extends Component{
	render(){
		return(
			<div>
        <h5 style={comingSoonStyle}>即將開放</h5>
			</div>
		)
	}
}

export default ComingSoon;
