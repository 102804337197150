import React from 'react'
//plugin
import axios from 'axios';
//Router
import {Redirect } from 'react-router-dom';
//config
import config from '../config';

class Logout extends React.Component {
  componentDidMount(){
    this.logout();
  }
  logout(){
    let self = this;
    axios.defaults.withCredentials = true;
		axios.get(`${config.server}/api/logout`)
		.then(function(response){
      if(response.data === 'logout'){
        self.props.userStore.login = false;
        self.props.userStore.username = '';
        self.props.userStore.order = new Map();
      }
		})
		.catch(function (error) {
		console.log(error);
		});
	}
  render () {
    return(
      <Redirect to='/' />
    )
  }
}

export default Logout;
