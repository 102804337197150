import React from 'react'
import PropTypes from 'prop-types'
import TitleMain from '../components/titleMain';
import Register from '../components/register';
import {observer} from 'mobx-react';

@observer
class Signup extends React.Component {
  render () {
    return(
      <div className="Signup">
        <TitleMain name='註冊'/>
        <Register store={this.props.userStore}/>
      </div>
    )
  }
}

export default Signup;
