import React ,{Component} from 'react';
import '../assets/css/sidebar.css';

class Sidebar extends Component{
	render(){
		return(
			<div className='sidebar' id='sidebar'>
				<ul>
					<h4>文章分類</h4>
					<li className='sidebarListActive' id='educationService'>教學服務</li>
					<li className='sidebarList' id='onlinePlatform'>在線平台</li>
					<li className='sidebarList' id='caseStudy'>案例學習</li>
				</ul>
			</div>
		)
	}
}

export default Sidebar;
