import React , {Component} from 'react';

//import plugins
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/js/uikit-icons.min.js';




class CardMedium extends Component{
	render(){
		return(
			<div className="uk-card uk-card-default uk-width-1-1" id={this.props.name} uk-grid="true" style={{width:'80%',padding:0,margin:'50px 10%'}} >
    		<div className="uk-card-media-left uk-cover-container uk-width-1-3@m uk-width-1-1">
        	<img src={this.props.img} alt={this.props.name} uk-cover="true"/>
					<canvas height='400'></canvas>
    		</div>
    		<div className="uk-card-body uk-card-media-right uk-width-2-3@m uk-width-1-1 uk-text-left">
					<h2 className="uk-card-title">{this.props.title}</h2>
					<p>{this.props.content}</p>
				</div>
			</div>
		)
	}
}

export default CardMedium;
