import kit_keyboardPiano_bg from '../assets/img/kit_keyboardPiano_bg.png';
import kit_keyboardPiano from '../assets/img/kit_keyboardPiano.png';
import kit_carLineTracking_bg from '../assets/img/kit_carLineTracking_bg.png';
import kit_carLineTracking from '../assets/img/kit_carLineTracking.png';
import kit_LEDMiniBox_bg from '../assets/img/kit_LEDMiniBox_bg.png';
import kit_LEDMiniBox from '../assets/img/kit_LEDMiniBox.png';
import kit_bipedRobotBasic_bg from '../assets/img/kit_bipedRobotBasic_bg.png';
import kit_bipedRobotBasic from '../assets/img/kit_bipedRobotBasic.png';
import kit_weatherStation_bg from '../assets/img/kit_weatherStation_bg.png';
import kit_weatherStation from '../assets/img/kit_weatherStation.png';
import kit_robotArm_bg from '../assets/img/kit_robotArm_bg.png';
import kit_robotArm from '../assets/img/kit_robotArm.png';
import video_keyboardPiano from '../assets/video/keyboardPiano.mp4';
import video_robotCarLineTracker from '../assets/video/robotCarLineTracker.mp4';
import video_LEDMiniBox from '../assets/video/LEDMiniBox.mp4';
import video_bipedRobot from '../assets/video/bipedRobot.mp4';
import video_weatherStaion from '../assets/video/weatherStation.mp4';
import video_robotArm from '../assets/video/robotArm.mp4';

const curriculumKit ={
	0:{
		name:'創意電子',
		nameEN:'Creative Electronics',
		desc:'創意電子課程將圍繞聲音和燈光兩大主題，帶領學生進行創意探索及動手製造，從中領略聲音和燈光的魅力。通過學習這門課程，學生能夠製作出彈奏美妙音樂的電子樂器，或者是流光溢彩的燈光設備，同时，獲得空間想像力、邏輯思維能力、创意设计能力的提升。',
		modules:['m0','i2','o0','o1'],
		accessories:['usb','dupont'],
		bgImg:kit_keyboardPiano_bg,
		img:kit_keyboardPiano,
		cat:'入門套裝',
		video:video_keyboardPiano,
    outline:['學習音樂、灯光電子項目的科學原理','通過3D建模設計電子琴、呼吸灯的個性化外觀','學習LED模组、音樂模组、觸摸感应模组的功能原理，並結合 CocoBlockly學習圖形化編程，實現不同的功能效果','成果展示及改进、教学评估'],
    outlineLesson:['1課時','3～5課時','3～5課時','2課時'],
		data:'https://drive.google.com/open?id=1shOTG9JRQX7b1SVDD_rxARvGuOxd1Fhwnwvu8N0BV5s',
	},
	1:{
		name:'動力小車',
		nameEN:'Robot Car',
		desc:'動力小車課程將圍繞動力主題，帶領學生進行創意探索及動手製造。通過這門課程的學習，學生能夠親手製作出屬於自己的個性化小車，並能夠在賽場競技。同时，提高自己的空间想象力、邏輯思維能力、问题解决能力。',
		modules:['m0','o3','i0','c1','a1','a1'],
		accessories:['IRSensor','rubberCircle','usb','dupont','motor'],
		bgImg:kit_carLineTracking_bg,
		img:kit_carLineTracking,
		cat:'中階套裝',
		video:video_robotCarLineTracker,
		outline:['學習電子小車的科學原理','小車外觀的3D建模及製作','加入電機驅動、教學模塊，並結合CocoBlockly學習圖形化編程，實現不同的功能效果','成果展示及改进、教学评估'],
		outlineLesson:['1課時','3～5課時','3～5課時','2課時'],
		data:'https://drive.google.com/open?id=1-H-H5C1GccI3Wi-gqQZdjR9dCnTdZM_6o1fEltKy9dk',
	},
	2:{
		name:'機械達人',
		nameEN:'Robot Guru',
		desc:'機械達人課程將圍繞機械主題，帶領學生進行創意探索及動手製造。學生將通過本次課程，學會親手製作一款个性化百變雙足機器人或者機械臂，並能用它來進行競技，同時，獲得空間想像力、邏輯思維能力、實踐能力的提升。',
		modules:['m0','o4','a2','a2','c1','a3','i0'],
		accessories:['servo','usb'],
		bgImg:kit_bipedRobotBasic_bg,
		img:kit_bipedRobotBasic,
		cat:'中階套裝',
		video:video_bipedRobot,
		outline:['學習機器人、机械臂的科學原理','機器人、机械臂外觀的3D建模及製作','加入舵機轉接模組、藍牙模組，並結合CocoBlockly學習圖形化編程，實現不同的功能效果','成果展示及改进、教学评估'],
		outlineLesson:['1課時','3～5課時','3～5課時','2課時'],
		data:'https://drive.google.com/open?id=1sxImGLnnMrrECQ9Ejd0TJ6iPzUtPLsSAtUZlMXCYKog',
	},
	3:{
		name:'智能家居',
		nameEN:'Smart Home Kit',
		desc:'智能創造課程將圍繞物聯網主題，帶領學生進行創意探索及動手製造。通過學習這門課程學生能夠製作一個可以採集環境的溫／濕度等數據迷你氣象站，並可以通過Wi-Fi實現數據的傳輸。同時，在這門課程中，學生能夠獲得空間想象力，邏輯思維能力、問題解決能力的提升。',
		modules:['m0','c0','i5','o2','a1','a3'],
		accessories:['usb','dupont','soilMoistureSensor','rainSensor','RTCSensor','gasSensor','PIRSensor','ultrasonicSensor','flameSensor','vibrationSensor','IRSensor','tiltSensor','lightSensor'],
		cat:'高階套裝',
		bgImg:kit_weatherStation_bg,
		img:kit_weatherStation,
		video:video_weatherStaion,
		outline:['學習物聯網的科學原理','智能家居的3D建模設計及制作','學習環境傳感模塊、OLED模塊、Wi-Fi無線通信模塊的功能原理，並結合CocoBlockly學習圖形化編程，實現不同的功能效果','成果展示及改进、教学评估'],
		outlineLesson:['1課時','3～5課時','3～5課時','2課時'],
		data:'https://drive.google.com/open?id=1RY1JKxTrsf_dCqKDae92DW5cTSWW8P9Zt3M_tPFu-1c',
	},
}

export default curriculumKit;
