import React from 'react'
import PropTypes from 'prop-types'
import Button from '../components/button';
import '../assets/css/fancyBtn.css';

class FancyBtn extends React.Component {
  iconToggle(){
    document.querySelectorAll('.FancyBtn__Icon')[0].classList.toggle('FancyBtn__Icon--active');
  }
  render () {
    return(
      <div className='FancyBtn' onMouseEnter={this.iconToggle.bind(this)} onMouseLeave={this.iconToggle.bind(this)}>
        <h2 className='FancyBtn__Icon'>→</h2>
        <h3 className='FancyBtn__Name'>{this.props.name}</h3>
      </div>
    )
  }
}

export default FancyBtn;
