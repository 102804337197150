import React from 'react'
import PropTypes from 'prop-types'
//css
import '../assets/css/login.css';
//components
import Modal from '../components/modal';
import Button from '../components/button';
//plugin
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
//material
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
//geetest
import Geetest from 'react-geetest';
import GoogleLogin from 'react-google-login';
//config
import config from '../config';
//mobx
import { observer } from 'mobx-react';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

@observer
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.state = {
      reCapchaToken: '',
      geetest: false,
      gt: '',
      challenge: '',
      geetest_challenge: '',
      geetest_validate: '',
      geetest_seccode: '',
      prompt: '',
      loginPress: false
    }
  }
  componentDidMount() {
    this.geetestRegister();
    document.addEventListener('keydown', this.loginKeyUp);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.loginKeyUp)
    this.props.userStore.directToRegister = false;
  }
  postMessageToParent(id, status, data) {
    //TODO: replace origin
    let result = {
      id,
      status,
      data
    }
    window.parent.postMessage(result, '*')
  }
  async login() {
    this.setState({
      prompt: '',
      loginPress: true,
    })
    // await this.greCaptcha();
    this.props.userStore.isLoading = true;
    let self = this;
    axios.defaults.withCredentials = true;
    axios.post(`${config.server}/api/user`, {
      loginUsername: document.getElementById('Login__input--username').value.toLowerCase(),
      loginPassword: document.getElementById('Login__input--pwd').value,
      grecaptcha: this.state.reCapchaToken,
      geetest_challenge: this.state.geetest_challenge,
      geetest_validate: this.state.geetest_validate,
      geetest_seccode: this.state.geetest_seccode
    })
      .then(function (response) {
        if (response.data == 'logged') {
          //get profile
          axios.defaults.withCredentials = true;
          axios.get(`${config.server}/api/profile`)
            .then(function (response) {
              self.props.userStore.username = response.data.username;
              self.props.userStore.alias = response.data.alias;
              self.props.userStore.login = true;
              self.props.userStore.isLoading = false;
              self.postMessageToParent('loginVerify', 'logged', response.data);
              self.setState({
                loginPress: false
              })
            })
            .catch(function (error) {
              console.log(error);
            })
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => (
        error.response.status == 401
          ? error.response.data == 'reCaptcha verify fails.'
            ? self.setState({ geetest: true, prompt: '請點擊驗證', loginPress: false })
            : (self.setState({ prompt: '無效的用戶名或密碼', loginPress: false }), self.props.userStore.isLoading = false)
          : null
      )
      );
  }
  getEmail() {
    document.getElementById('Login__resetEmail').value = document.getElementById('Login__input--username').value;
  }
  resetSubmit() {
    let self = this;
    axios.defaults.withCredentials = true;
    axios.post(`${config.server}/reset`, {
      resetEmail: document.getElementById('Login__resetEmail').value,
    })
      .then(function (response) {
        if (response.data == 'SENT') {
          window.alert('郵件已發出');
          document.querySelectorAll('.Login__resetEmail--prompt')[0].innerHTML = '';
        } else {
          document.querySelectorAll('.Login__resetEmail--prompt')[0].innerHTML = '無效的郵箱';
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  responseGoogle(response) {
    let self = this;
    axios.defaults.withCredentials = true;
    axios.post(`${config.server}/googleSignIn`, {
      googleTokenId: response.tokenId
    })
      .then(function (res) {
        if (res.data.status == 'UNREGISTERED') {
          self.props.userStore.username = res.data.username;
          self.props.userStore.googleId = res.data.googleId;
          self.props.userStore.directToRegister = true;
        } else if (res.data == 'logged') {
          //get profile
          axios.get(`${config.server}/api/profile`)
            .then(function (response) {
              self.props.userStore.username = response.data.username;
              self.props.userStore.alias = response.data.alias;
              self.props.userStore.login = true;
              self.props.userStore.isLoading = false;
              self.postMessageToParent('loginVerify', 'logged', response.data);
            })
            .catch(function (error) {
              console.log(error);
            })
        }
      })
      .catch(function (err) {
        console.log(err);
      })
  }
  greCaptcha() {
    return new Promise((resolve, reject) => {
      let self = this;
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute(config.reCaptchaSiteKey, { action: 'login' }).then(function (token) {
          self.setState({
            reCapchaToken: token
          });
          return resolve(true);
        });
      });
    });
  }
  geetestRegister() {
    let self = this;
    axios.get(`${config.server}/geetest/pc-geetest/register`)
      .then((res) => {
        self.setState({
          challenge: res.data.challenge,
          gt: res.data.gt
        })
      })
      .catch((err) => {
        console.log(err);
      })
  }
  geetestValidate(isSuccess) {
    this.setState({
      geetest_challenge: isSuccess.geetest_challenge,
      geetest_validate: isSuccess.geetest_validate,
      geetest_seccode: isSuccess.geetest_seccode
    })
  }
  loginKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.login()
    }
  }
  render() {
    if (this.props.userStore.login) {
      return <Redirect to='/success' />;
    }
    if (this.props.userStore.directToRegister) {
      return <Redirect to='/signup' />
    }
    const { loading, success } = this.state;
    const { classes } = this.props;
    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });
    return (
      <div>
        <div className='Login' id='Login' onKeyDown={this.loginKeyUp}>
          <div className='Login__account'>
            <span className='Login__title'>電子郵件</span>
            <input type='text' className='Login__input' id='Login__input--username' />
            <p className='Login__prompt'>{this.state.prompt}</p>
          </div>
          <div className='Login__pwd'>
            <span className='Login__title'>密碼</span>
            <input type='password' className='Login__input' id='Login__input--pwd' />
          </div>
          <div className="Login__register">
            <Link to='/signup'>註冊</Link>
          </div>
          <div className='Login__button--wrapper'>
            {this.state.loginPress
              ? <React.Fragment>
                <button className='Login__button--press' onClick={this.login}>登入</button>
                <CircularProgress size={24} className={classes.buttonProgress} />
              </React.Fragment>
              : <button className='Login__button' onClick={this.login}>登入</button>
            }
          </div>
          <a uk-toggle='target:#Login__modal--forget' className='Login__forget' onClick={this.getEmail}>忘記密碼？</a>
          <div className='Login__recaptcha'>
            {this.state.gt && this.state.challenge && this.state.geetest
              ? <Geetest
                gt={this.state.gt}
                challenge={this.state.challenge}
                onSuccess={this.geetestValidate.bind(this)}
                lang='zh-hk'
              />
              : null
            }
          </div>
          <div className="Login__googleSignIn" id='googleButton'>
            <GoogleLogin
              clientId="761552586901-3qqsoork4mgetksuttd1o719ctq9k98p.apps.googleusercontent.com"
              buttonText="Google Sign in"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
            />
          </div>
        </div>
        <Modal id='Login__modal--forget'>
          <h3>重置您的密碼</h3>
          <p>我們將向下列郵箱發送一封重置密碼的郵件</p>
          <input className='Login__resetEmail' id='Login__resetEmail' />
          <span className='Login__resetEmail--prompt'></span>
          <Button name='確認' fn={this.resetSubmit} />
        </Modal>
      </div>
    )
  }
}

export default withStyles(styles)(Login);