import React, { Component } from 'react';
import TitleMain from './titleMain';
import '../assets/css/downLoad.css'



class Download extends Component {

    componentDidMount = () => {
        // console.log(document.getElementsByTagName('footer')[0].style)
        document.getElementsByTagName('footer')[0].style.marginTop = 0;
    }

    componentWillUnmount = () => {

        document.getElementsByTagName('footer')[0].style.marginTop = 100 + 'px';
    }


    render() {
        return (
            <div className="downLoad">
                {/* <TitleMain name='下載' /> */}
                <div className="top">
                    <h1 style={{ fontWeight: "600" }}>CocoBlockly</h1>
                    <p>基於 Blockly 的積木式硬件編程工具</p>
                </div>
                <div className="down">
                    <div className="downLeft first" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/6.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">CocoBlockly 上傳插件</span>
                        <p className="content">爲了能夠讓 CocoBlockly 能夠從瀏覽器和主控模塊進行通信，我們推出一款跨平臺的上傳插件（Uploader），用戶下載該插件並安裝，隨後即可訪問 CocoBlockly，對我們的電子模塊進行上傳編程。</p>
                        <p className="setting">支持的操作系統：</p>
                        <span>Windows / macOS</span>
                        <div className="downing">
                            <a href="https://api.cocorobo.hk/releases/uploaderv2/download/windows">
                                <button>下載 Windows 版本</button>
                            </a> &nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="http://cocorobo.hk/downloads/uploaderv2_mac_v3.0.8.pkg">
                                <button>下載 MacOS 版本</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft" style={{ lineHeight: '220px' }}>
                        <img src={require('../assets/img/CocoBlockly_X_Uploader.png')} style={{ borderRadius: 10 }} />
                    </div>
                    <div className="downRight">
                        <span className="downName">CocoBlockly X 二代上傳插件</span>
                        <p className="content">爲了能夠讓 CocoBlockly X 能夠從瀏覽器和我們二代的 AI 和 IoT 模塊進行通信，進行上傳和運行的操作，我們推出一款跨平臺的上傳插件，用戶下載該插件並安裝，隨後便可以對我們的電子模塊進行上傳編程。</p>
                        <p className="setting">支持的操作系統：</p>
                        <span>Windows / macOS</span>
                        <div className="downing">
                            <a href="http://api.cocorobo.hk/releases/pythonuploaderv1.0.5/download/windows">
                                <button>下載 Windows 版本</button>
                            </a> &nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="http://api.cocorobo.hk/releases/pythonuploaderv1.0.5/download/mac">
                                <button>下載 MacOS 版本</button>
                            </a>
                        </div>
                    </div>
                </div>
                <h1 className="titleName">推薦瀏覽器</h1>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/3.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">下載谷歌浏览器</span>
                        <p className="content">使用谷歌瀏覽器，在線編程體驗更流暢</p>
                        <div className="downing">
                            <a target="_blank" href="https://www.google.cn/chrome/index.html">
                                <button>前往下載</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/7.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">360安全瀏覽器</span>
                        <p className="content">CocoBlockly 編程工具在 360 極速瀏覽器中運行，讓你在線編程體驗進行流暢</p>
                        <div className="downing">
                            <a target="_blank" href="https://browser.360.cn">
                                <button>前往下載</button>
                            </a>
                        </div>
                    </div>
                </div>
                <h1 className="titleName">輔助工具及軟件</h1>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/4.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">TeamViewer 遠程協助軟件</span>
                        <p className="content">隨時隨地連接到遠程桌面電腦，讓遠程連接過程更加的快速和安全，輕鬆訪問文件、網絡及程序</p>
                        <div className="downing">
                            <a target="_blank" href="https://www.teamviewer.cn/cn/download/">
                                <button>前往下載</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="down">
                    <div className="downLeft">
                        <img src={require('../assets/img/5.png')} />
                    </div>
                    <div className="downRight">
                        <span className="downName">ZOOM 遠程會議軟件</span>
                        <p className="content">多人手機雲視頻會議軟件，提供兼備高清視頻與移動網絡會議功能的免費雲視頻通話</p>
                        <div className="downing">
                            <a target="_blank" href="https://zoom.com.cn/download">
                                <button>前往下載</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Download;