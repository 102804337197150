import React, { Component } from 'react';
import TitleMain from './titleMain';
import cocoBlockly from '../assets/img/blockly-1.png';
import cocoBlocklyX from '../assets/img/blockly-2.png';
import cocoPlayground from '../assets/img/cocoPlayground_t.svg';
import cocoARApp from '../assets/img/cocoARApp_t.svg';
import cocoCAD from '../assets/img/cococad.png';
import Maker from '../assets/img/3DMaker_t.svg';
import cocoCloud from '../assets/img/cloud.png';

import '../assets/css/create.css';
import config from '../config';
//plugin
import Tilt from 'react-tilt';


const AButton = { padding: '5px 10px', background: '#fff', color: '#2e3e3f', fontSize: 14, borderRadius: 3, fontWeight: 600, position: 'absolute', bottom: 25, left: 'calc(50% - 38px)' }
const TiltInner = { background: '#2e3e3f', color: '#fff', padding: '0 0 20px 0', minHeight: 440, position: 'relative' }

class create extends Component {
	render() {
		return (
			<div>
				<TitleMain name='創作' />
				<div className="create">
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={cocoBlockly} className='' alt="CocoBlockly" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>CocoBlockly</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>使用 CocoBlockly (Arduino) 為我們一代電子模塊進行編程</p>
							<a href={`${config.host}/cocoblockly/dev/`} target="_blank" style={AButton}>點擊進入</a>
						</div>
						<a href={`${config.host}/legacy/cocoblockly/dev/`} style={{ margin: '10px 0', display: 'block' }} uk-tooltip='title:適用於2018年8月前的版本;pos:bottom'>舊版入口</a>
						<a href={`${config.host}/legacy/cocoblocklyv1`} style={{ margin: '10px 0', display: 'block' }}>Codebender 版本入口</a>
					</Tilt>
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={cocoBlocklyX} className='' alt="CocoBlockly X" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>CocoBlockly X</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>使用 CocoBlockly X 為我們二代電子模塊進行編程</p>
							<a href={`//x.cocorobo.hk/`} target="_blank" style={AButton}>點擊進入</a>
						</div>
					</Tilt>
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={cocoCloud} width='100%' alt="cocoCloud" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>Coco Cloud</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>CocoCloud 是 CocoRobo 推出的雲端物聯網平臺,用戶可以在改平臺上進行物聯網事件的管理,以及時間狀態的查看。CocoCloud 支持數據可視化功能,用戶可以控制面板中的創建,並查看各類的數據</p>
							<a href={`${config.host}/cloud/`} target="_blank" style={AButton}>點擊進入</a>
						</div>
					</Tilt>
					<Tilt className="Tilt" options={{ max: 25 }} >
						<div className="Tilt-inner" style={TiltInner}>
							<img style={{ width: '80%' }} src={cocoCAD} alt="cocoCAD" />
							<h3 style={{ fontWeight: 700, color: '#fff', marginTop: 0 }}>Coco CAD</h3>
							<p style={{ padding: '0 15px', fontSize: 14 }}>與 CocoBlockly 原理相似，這款 Coco-CAD 同樣是使用積木的方式讓學生體驗到通過漸進式方法來進行 3D 建模</p>
							<a href={`${config.host}/cococad/`} target="_blank" style={AButton}>點擊進入</a>
						</div>
					</Tilt>

					{/*<Tilt className="Tilt" options={{ max : 25 }} >
					 <div className="Tilt-inner">
						<a href='#'><img style={{height:100}}src={cocoPlayground} alt="cocoPlayground"/></a>
					 </div>
					</Tilt>
					<Tilt className="Tilt" options={{ max : 25 }} >
					 <div className="Tilt-inner">
						<a href='#'><img style={{height:100}}src={cocoARApp} alt="cocoARApp"/></a>
					 </div>
					</Tilt>
					<Tilt className="Tilt" options={{ max : 25 }} >
					 <div className="Tilt-inner">
						<a href='#'><img style={{height:100}}src={Maker} alt="3DMaker"/></a>
					 </div>
					</Tilt>*/}
				</div>
			</div>
		)
	}
}

export default create;
