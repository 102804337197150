import React from 'react'
import PropTypes from 'prop-types'
import '../assets/css/basicKit.css'
import Button from './button'
import {Link} from 'react-router-dom'

class BasicKit extends React.Component {
  render () {
    return(
      <div className="BasicKit" id={this.props.id}>
        <img src={this.props.img} alt='BasicKitImg' width='100%' />
        <Link to='/order' className="BasicKit__quiryBtn">
          {/*<Button
            name='詢價'
            size='button--medium'
            style={{backgroundColor:'#5A7493'}}
            />*/}
        </Link>
      </div>
    )
  }
}

export default BasicKit;
