import React from 'react'
import PropTypes from 'prop-types'
import modules from './modules'
import accessories from '../data/accessories';
import '../assets/css/package.css'

class Package extends React.Component {
  render () {
    return(
      <div className='Package'>
          {this.props.modules.map(x=>(<div><img className='Package__img' src={modules[x.charAt(0)][x].img} alt={modules[x.charAt(0)][x].name} width='100%'/><span>{modules[x.charAt(0)][x].name}</span></div>))}
          {this.props.accessories.map(x=>(<div><img className='Package__img' src={accessories[x].img} alt={accessories[x].name} width='100%'/><span>{accessories[x].nameTC}</span></div>))}
      </div>
    )
  }
}

export default Package;
