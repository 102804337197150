import discover_img_1 from '../assets/img/discover_img_1.jpg';

const guide = {
	blockly:{
		0:{
			content:'ABCDEFG',
			img:discover_img_1,
			title:'Title',
		},
		1:{
			content:'sdfdd',
			img:discover_img_1,
			title:'ddddd',
		},
		2:{
			content:'ABCDEFG',
			img:discover_img_1,
			title:'Title',
		},
		3:{
			content:'sdfdd',
			img:discover_img_1,
			title:'ddddd',
		},
		4:{
			content:'sdfdd',
			img:discover_img_1,
			title:'ddddd',
		},
	},
	demo:{
		0:{
			content:'ABCDEJKLJKLDFG',
			img:discover_img_1,
			title:'Title',
		},
		1:{
			content:'HHHHH',
			img:discover_img_1,
			title:'ddddd',
		},
	},
}

export default guide;
