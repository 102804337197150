import React, { Component } from 'react';
import TitleMain from './titleMain';
import Kit from './kit';
import kit from '../data/kit';
import basicKit from '../data/basicKit';
import BasicKit from './basicKit';
import '../assets/css/kit.css';




class CocoKit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			video: '',
			desc: '',
			modal: true,
		}
	}
	modalState(i, event) {
		this.setState({
			title: kit[i].name,
			video: kit[i].video,
			desc: kit[i].desc,
		})
	}
	render() {
		return (
			<div>
				<TitleMain name='CocoKit &#8482; 套件' />
				{/* <Kit
						key={kit[5].name}
						// desc={kit[5].desc}
						// modules={kit[5].modules}
						// lists={kit[5].lists}
						// bgImg={kit[5].bgImg}
						// bgColor={kit[5].bgColor}
						img={kit[5].img}
						name={kit[5].name}
						nameEN={kit[5].nameEN}
						cat={kit[5].cat}
						fn={this.modalState.bind(this, 5)}
						id={kit[5].id}
						video={kit[5].video}
						// className='kit--fullKit'
					/> */}
				{Object.keys(kit).map((x, y) =>
					<Kit
						key={kit[x].name}
						// desc={kit[x].desc}
						// modules={kit[x].modules}
						// lists={kit[x].lists}
						// bgImg={kit[x].bgImg}
						// bgColor={kit[x].bgColor}
						img={kit[x].img}
						// name={kit[x].name}
						nameEN={kit[x].nameEN}
						cat={kit[x].cat}
						fn={this.modalState.bind(this, y)}
						id={kit[x].id}
						video={kit[x].video}
					/>)}
				<div id='modal-video' uk-modal='true' className='uk-flex-top'>
					<div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
						<h2>{this.state.title}</h2>
						<p>{this.state.desc}</p>
						<video controls={true} playsInline uk-video='true' width="100%" src={this.state.video}></video>
						<button className="uk-modal-close-outside" uk-icon='close'>X</button>
					</div>
				</div>
			</div>
		)
	}
}

export default CocoKit;
