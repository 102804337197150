import React , {Component} from 'react';
import TitleMain from '../components/titleMain';
import '../assets/css/contact.css';

//plugin
import scrollToComponent from 'react-scroll-to-component';

class Contact extends Component{
	componentDidMount(){
		scrollToComponent(this.Container, { offset: -150, align: 'top', duration: 500, ease:'inCirc'});
	}
	render(){
		return(
			<div ref={(container)=>{this.Container = container}}>
				<TitleMain name='聯繫我們' />
				<div className='contact'>
					<div className='wrapper'>
						<div>
						<h3>商務合作</h3>
						<p>Tony</p>
						<a href='mailTo:support-hk@cocorobo.cc'>support-hk@cocorobo.cc</a>
						</div>
						<div>
						<h3>技術支持</h3>
						<p>Tommy</p>
						<a href='mailTo:tommy@cocorobo.cc'>tommy@cocorobo.cc</a>
						</div>
						<div>
						<h3>技術支持</h3>
						<p>Jake</p>
						<a href='mailTo:test@cocorobo.hk'>jakeluo@cocorobo.cc</a>
						</div>
						<div>
						<h3>地址</h3>
						<p>HongKong</p>
						<p>Unit 719B, 7/F, Enterprise Place No. 5 Science Park West Avenue Hong Kong Science Park, Shatin, N.T. HK</p>
						<p>中國內地</p>
						<p>深圳市南山區虛擬大學園區粵興二道10號香港中文大學深圳研究院3樓310</p>
						<p>郵編：518000</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Contact;
