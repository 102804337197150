import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom';

const showAllListStyle={
  position:'absolute',
  right:0,
  top:'10%',

};
class ShowAllList extends React.Component {
  render () {
    return(
      <div className="showAllList" style={showAllListStyle}>
        <Link to='/explore/guide'><p>查看所有</p></Link>
      </div>
    )
  }
}

export default ShowAllList;
