import React ,{Component} from 'react';
import TitleMain from './titleMain';
import Article from './article';
import Sidebar from './sidebar';
import Image from './image';
import {Router ,Route } from 'react-router-dom';
//img
import service_education from '../assets/img/service_education.jpg';
import service_onlinePlatform from '../assets/img/service_onlinePlatform.png';
import service_caseStudy from '../assets/img/service_caseStudy.jpg';
import service_s1 from '../assets/img/service_s1.png';
import service_s2 from '../assets/img/service_s2.png';
import service_s3 from '../assets/img/service_s3.png';
import service_s4 from '../assets/img/service_s4.png';
//css
import '../assets/css/service.css';


//import plugins
import nl2br from 'react-newline-to-break';


const service = {
	educationService:{
			img:service_education,
			title:'教學服務',
			tag:'教學服務',
			content:'1.課程定製方案\n\n基於不同學校的要求和特點，我們針對性的課程評估方案，以及個性化的課程定製方案 \n\n2.教師培訓\n\n由我方對學生老師進行培訓，現正在與香港中文大學教育學院深度合作，共同推出師資培訓認證 \n\n3.到校服務\n\n根據學校的需求，我方可以提供課程、教學及技術方面的課堂支持',
	},
	onlinePlatform:{
			img:service_onlinePlatform,
			title:'在線平台',
			tag:'在線平台',
			content:'CocoRobo 將提供配套的在線平台，供學生和老師在課後討論用，並進行作業上傳。\n\n我們提供產品百科和項目探索版塊，學生亦可在我們的在線網站上找到相對應的資源自行學習。配合產品與課程，我們推出了三款雲端開發平台（IDE）方便用户使用。用户可以免費註冊，再根據我們提供的教學方案進行學習。此外，結合我們的線下及線上課程，用户可以在線提交作業，讓老師批閲和反饋。',
	},
	caseStudy:{
			img:service_caseStudy,
			title:'案例學習',
			tag:'案例學習',
			content:'針對中三學生的STEM課程，計劃採用項目制教學法進行授課，利用11周，22次課時來授課。\n\n使用 Problem-based Learning 方法，我們會引導學生利用前一單元所掌握的關於「3D模型設計」、「3D列印技術」、「電子電路」，以及「程式設計」等技能，來針對校園，設計未來心中的智慧校園的場景。在這過程中，學生會進一步學習各種感測器的模組的使用，更高階的3D繪圖方法和電腦程式設計技能等',
	},
}

class Service extends Component{
	constructor(props){
		super(props);
		this.state={
			title:service.educationService.title,
			tag:service.educationService.tag,
			img:service.educationService.img,
			content:nl2br(service.educationService.content),
		}
	}
	componentDidMount(){
		// document.getElementById('sidebar').addEventListener('click',this.changeResult.bind(this));
		this.scrollToTop();

	}
	scrollToTop(){
		window.scrollTo(0,0);
	}
	changeResult(event){
		if(event.target.nodeName === 'LI'){
			let getName = event.target.id;
			document.querySelectorAll('.sidebarListActive')[0].className = 'sidebarList';
			document.getElementById(getName).className ='sidebarListActive';
		this.setState({
			title:service[getName].title,
			tag:service[getName].tag,
			content:nl2br(service[getName].content),
			img:service[getName].img,
		});
		}
	}
	render(){
		return(
			<div className='Service'>
				<TitleMain name='教師培訓' />
				<div className="Service__section">
					<Image src={service_s1} />
					<p>CocoRobo為學校提供專業和全面的教師培訓服務，讓老師能迅速掌握教學方式。</p>
				</div>
				<TitleMain name='到校授課' />
				<div className="Service__section">
					<Image src={service_s2} />
					<p>根據學校的不同需求，CocoRobo可以提供課程、教學以及技術方面的課堂支持</p>
				</div>
				<TitleMain name='賽事主辦' />
				<div className="Service__section">
					<Image src={service_s3} />
					<p style={{width:'80%',margin:'50px auto '}}>為推廣STEM教育的理念，並促進各校之間辦學心得、學術成果的交流，CocoRobo聯合各校長會舉辦了以「3D打印機械人對抗賽」為主題的系列化STEM賽事。鼓勵學生將課堂所學的STEM知識運用於實際比賽中，從而提高學生對STEM的認識及學習興趣。</p>
					<a href='http://ccc.3dprobot.org' target='_blank'>比賽連結</a>
				</div>
				<TitleMain name='科技節' />
				<div className="Service__section">
					<Image src={service_s4} />
					<p>針對豐富的課外活動，CocoRobo可以為校園科技節、興趣社團等提供課程、技術諮詢等方面的支持</p>
				</div>








				{/*<Article
					title={this.state.title}
					tag={this.state.tag}
					img={this.state.img}
					content={this.state.content} />
				<Sidebar />*/}
			</div>
		)
	}
}

export default Service;
