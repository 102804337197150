var config = {

  // production
  server:'//api.cocorobo.hk',
  basename:'/',   
  host:'//cocorobo.hk',
  reCaptchaSiteKey:'6Le4c4AUAAAAAJVIiYp3e0fO3KqyVo_FWdwBDf9Y'

}

export default config;
