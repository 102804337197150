import React , {Component} from 'react';
import TitleMain from '../components/titleMain';
import officePic from '../assets/img/officePic.jpg';
import '../assets/css/office.css';
import Button from '../components/button';
//plugin
import scrollToComponent from 'react-scroll-to-component';

const bgColor = {
	backgroundColor:'#000',
}
class About extends Component{
	componentDidMount(){
		scrollToComponent(this.Container, { offset: -150, align: 'top', duration: 500, ease:'inCirc'});
	}
	render(){
		return(
			<div ref={(container)=>{this.Container = container}}>
				<TitleMain name='關於我們' />
				<div className='office'>
					<div className='officePic'>
						<img src={officePic} alt='officePic'/>
					</div>
					<div className='desc'>
						<p><b>可可樂博（CocoRobo）</b>於2016年4月在香港成立，
						是一家專注於<b>STEAM教育（科技，技術，工程，藝術及數學）</b>
						的教育科技公司。我們的使命是通過靈感和科技的有機結合
						，將最新的理念和技術帶到STEAM教育領域，目前在香港和內地服務過近120家學校，
						知名學校包括弘立書院，英華書院，聖公會鄧肇堅中學等，並與香港中文大學教育學院、
						工程學院保持長期深度的合作。<br></br><br></br>團隊於2017年1月榮獲香港政府大學科技創業項目資助50萬港幣;
						7月入選香港科學園科技孵化器，並得到18萬港幣資助及場地贊助；9月和10月先後在深圳創新創業
						大賽和寶安創新創業大賽中取得優勝獎和一等獎，並獲得包括中國日報，大公報，明報等在內的香港
						及內地媒體的專訪。</p>
					</div>
					<a href='http://cocorobo.hk/hr/'>
						<Button name='加入我們' style={{backgroundColor:'#000'}}/>
					</a>

				</div>
			</div>
		)
	}
}

export default About;
