import React, {Component} from 'react';
import '../assets/css/faq.css';
import TitleMain from '../components/titleMain';
import FAQList from '../components/FAQList';
//plugin
import scrollToComponent from 'react-scroll-to-component';
import {Link} from 'react-router-dom';

export default class FAQ extends Component{
	componentDidMount(){
		scrollToComponent(this.Container, { offset: -150, align: 'top', duration: 500, ease:'inCirc'});
	}
	render(){
		return(
			<div ref={(container)=>{this.Container = container}}>
				<TitleMain name='FAQ' />
				<div className='faq'>
					<div className='category'>
						<h3>問題分類</h3>
						<span><Link to='/faq/cat1'>cat1</Link></span>
						<span><Link to='/faq/cat2'>cat2</Link></span>
						<span><Link to='/faq/cat3'>CocoBlockly</Link></span>
					</div>
					<FAQList />
				</div>
			</div>
		)
	}
}
