import React from 'react'
import PropTypes from 'prop-types'
import '../assets/css/competitionCard.css';
import linkSymbol from '../assets/img/link_symbol.svg';

class CompetitionCard extends React.Component {
  render () {
    return(
      <div className='CompetitionCard'>
        <img src={this.props.img} width='100%'/>
        <h2>{this.props.title}</h2>
        <h5>{this.props.date}</h5>
        <a href={this.props.link} style={{marginLeft:'10px',display:'flex',justifyContent:'center',alignItems:'center',paddingBottom:50}} target='_blank'>
          <h5 style={{margin:0,marginRight:10}}>{this.props.subtitle}</h5>
          <img src={linkSymbol} width='15px'/>
        </a>
      </div>
    )
  }
}

export default CompetitionCard;
