import React from 'react'
import PropTypes from 'prop-types'
import ComingSoon from '../components/comingSoon';
import TitleMain from '../components/titleMain';
import CompetitionCard from '../components/competitionCard';
//css
import '../assets/css/competition.css';

//image
import skh from '../assets/img/competition_skh.png';
import ccc from '../assets/img/competition_ccc.png';


class Competition extends React.Component {
  render () {
    return(
      <div>
        <TitleMain name='賽事' />
        <div className='Competition'>
          <div className='Competition__competitionCard'>
            <CompetitionCard title='「奪寶奇兵」' subtitle={'聖公會中學3D打印機械人對抗賽'} img={skh} link='http://skh.3dprobot.org' date={'July 23 2018'}/>
          </div>
          <div className='Competition__competitionCard'>
            <CompetitionCard title='「第一屆機械人對抗賽」' subtitle={'中華基督教會協和小學 (長沙灣)'} img={ccc} link='http://ccc.3dprobot.org' date={'Feb 1 2019'}/>
          </div>
        </div>
      </div>
    )
  }
}

export default Competition;
