
import React from 'react'
import PropTypes from 'prop-types'
//components
import TitleMain from './titleMain';
import {Route, Redirect} from 'react-router-dom';
import Button from './button';
//plugin
import axios from 'axios';
//config
import config from '../config';
//class
import '../assets/css/reset.css';



class Reset extends React.Component {
  constructor(props){
    super(props);
    this.postNewPassword = this.postNewPassword.bind(this);
    this.state={
      redirectToLogin:false
    }
  }
  componentWillMount(){
    this.verify();
  }
  verify(){
    let self = this;
    //get params
    let getURL = window.location.href;
    let url = new URL(getURL);
    let name = url.searchParams.get('name');
    let resetKey = url.searchParams.get('resetKey');
    let postData = {
      name: name,
      resetKey : resetKey,
    }
    axios.post(config.server+ '/reset/verify', postData)
    .then(function(res){
      if(res.data == 'OK'){
        self.setState({
          redirectToLogin:false
        })
      }else{
        alert('連結已失效');
        self.setState({
          redirectToLogin:true,
        })
      }
    })
    .catch(function(err){
      console.log(err);
    })
  }
  postNewPassword(){
    let self = this;
    //get params
    let getURL = window.location.href;
    let url = new URL(getURL);
    let name = url.searchParams.get('name');
    let resetKey = url.searchParams.get('resetKey');
    let postData = {
      name: name,
      resetKey : resetKey,
      newPassword: document.getElementById('Reset__newPassword').value,
    }
    axios.post(config.server+ '/reset/pwd', postData)
    .then(function(res){
      if(res.data == 'OK'){
        alert('密碼已更改');
        self.setState({
          redirectToLogin:true
        })
      }
    })
    .catch(function(err){
      console.log(err);
    })
  }
  render () {
    if(this.state.redirectToLogin == true){
      return <Redirect to='/login' />
    }
    return(
      <div>
        <TitleMain name='重置密碼'/>
        <div className='Reset'>
          <label className='Reset__title'>新密碼</label>
          <input id='Reset__newPassword' className='Reset__newPassword' type='password'></input>
          <div className='Reset__btn'>
            <Button name='提交' fn={this.postNewPassword}/>
          </div>
        </div>
      </div>
    )
  }
}
// const Child = ({match}) => (<div>{match.params.username}{match.params.resetCode}</div>)
export default Reset;
