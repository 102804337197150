import React , {Component} from 'react';
// import React, { Component } from 'react';


//import plugins
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import 'uikit/dist/js/uikit-icons.min.js';




class CardSmall extends Component {
	render() {
		return (
			<a className="uk-card uk-card-default uk-width-1-4 " style={{ padding: 0, margin: '0 calc(25%/6)' }} href={`#${this.props.name}`} data-uk-scroll="true">
				<div className="uk-card-media-top">
					<img src={this.props.img} alt={this.props.name} />
				</div>
				<div className="uk-card-body" style={{ padding: '10%' }}>{this.props.content}</div>
			</a>
		)
	}
}

export default CardSmall;
