import i0o0 from '../assets/img/placeholder/moduleSet_i0o0.jpg';
import i0o1 from '../assets/img/placeholder/moduleSet_i0o1.jpg';
import i0o3 from '../assets/img/placeholder/moduleSet_i0o3.jpg';
import i0o5 from '../assets/img/placeholder/moduleSet_i0o5.jpg';
import i1o0 from '../assets/img/placeholder/moduleSet_i1o0.jpg';
import i1o1 from '../assets/img/placeholder/moduleSet_i1o1.jpg';
import i1o3 from '../assets/img/placeholder/moduleSet_i1o3.jpg';
// import i2o0 from '../assets/img/placeholder/moduleSet_i2o0.jpg';
import i2o1 from '../assets/img/placeholder/moduleSet_i2o1.jpg';
import i2o3 from '../assets/img/placeholder/moduleSet_i2o3.jpg';
import i3o1 from '../assets/img/placeholder/moduleSet_i3o1.jpg';
// import i3o3 from '../assets/img/placeholder/moduleSet_i3o3.jpg';
import i4o0 from '../assets/img/placeholder/moduleSet_i4o0.jpg';
import i4o1 from '../assets/img/placeholder/moduleSet_i4o1.jpg';
import i5o0 from '../assets/img/placeholder/moduleSet_i5o0.jpg';
import i5o1 from '../assets/img/placeholder/moduleSet_i5o1.jpg';
import i5o2 from '../assets/img/placeholder/moduleSet_i5o2.jpg';
import i5o3 from '../assets/img/placeholder/moduleSet_i5o3.jpg';
import i5o4 from '../assets/img/placeholder/moduleSet_i5o4.jpg';
import i6o1 from '../assets/img/placeholder/moduleSet_i6o1.jpg';

import VideoArea from '../assets/img/videoArea.png';



const sets = {
    i0o0: {
        title: '可調節音量的小音箱',
        content: '利用彩色卡紙製作一個小音箱的造型，通過教學模塊上的按鈕／旋鈕，可以在播放音樂的同時調節小音箱的音量。',
        fn: '利用教學模塊上的旋鈕／開關／按鍵／紅外遙控等按鈕和傳感器，來控制音樂的播放 ',
        inputModule: 'i0',
        outputModule: 'o0',
        cat: 'music',
        // img: VideoArea,
        img: i0o0,
				// more:{
				// 	1:{
				// 		title: '多彩LED燈',
		    //     content: '多彩LED燈是通過教學模組的旋鈕裝置切換不同的燈光動畫模式。',
		    //     fn: '利用教學模塊上的旋鈕／開關／按鍵／紅外遙控等按鈕和傳感器，可以靈活操縱簡單又有趣的燈光裝置 ',
				// 		img:i0o1,
				// 		cat:'light',
				// 	},
				// 	2:{
				// 		title: '紅外控制小車',
		    //     content: '利用電機驅動模塊可以製作一個小車，並通過紅遙控器來控制小車的運動。',
		    //     fn: '利用教學模塊上的旋鈕／開關／按鍵／紅外遙控等按鈕和傳感器，可以控制物體的運動',
				// 		img:i0o3,
				// 		cat:'power',
				// 	},
				// },
    },
    i0o1: {
        title: '多彩LED燈',
        content: '多彩LED燈是通過教學模組的旋鈕裝置切換不同的燈光動畫模式。',
        fn: '利用教學模塊上的旋鈕／開關／按鍵／紅外遙控等按鈕和傳感器，可以靈活操縱簡單又有趣的燈光裝置 ',
        inputModule: 'i0',
        outputModule: 'o1',
        cat: 'light',
        img: i0o1,
    },
    i0o3: {
        title: '紅外控制小車',
        content: '利用電機驅動模塊可以製作一個小車，並通過紅遙控器來控制小車的運動。',
        fn: '利用教學模塊上的旋鈕／開關／按鍵／紅外遙控等按鈕和傳感器，可以控制物體的運動',
        inputModule: 'i0',
        outputModule: 'o3',
        cat: 'power',
        img: i0o3,
    },
    i0o5: {
        title: '簡易防盜裝置',
        content: '利用環境監測模塊的外接紅外線功能，識別運動的物體並通過蜂鳴器發出“滴滴滴”的聲音。可以運用在家中、商場、展會等的禁止觸摸物品、防盜竊的場景。',
        fn: '利用該模組可以通過感應聲音、温度、濕度、光照等外部環境條件的變化以及外接紅外線、超聲波等因素來控制蜂鳴器',
        inputModule: 'i0',
        outputModule: 'o5',
        cat: 'music',
        img: i0o5,
    },
    i1o0: {
        title: '空氣琴',
        content: '空氣琴的創意來源於一款十分神奇的樂器——特雷門琴，特雷門琴是世界上唯一不需要身體接觸的電子樂器。而空氣琴同樣不需要接觸任何鍵盤或者琴絃就能夠彈奏出動人的音樂，只需要在一副手套上安裝我們的電子模組並對其進行編程就能完成空氣琴的製作，通過肢體的運動，就可以使空氣琴發出各種樂器的聲音啦。',
        fn: '利用該模組可以通過改變肢體動作來控制音樂的播放 ',
        inputModule: 'i1',
        outputModule: 'o0',
        cat: 'music',
        // img: VideoArea,
        img:i1o0,
    },
    i1o1: {
        title: '運動燈光手環',
        content: '小朋友可以根據自己的想象設計出獨一無二的運動燈光手環，並且用3D打印機打印出來。在手環內部放入我們的模塊組合五。運動感應模塊會感應到肢體的運動變化，從而改變燈光的顏色和亮度。',
        fn: '利用該模組可以通過改變肢體動作來影響燈光的顏色',
        inputModule: 'i1',
        outputModule: 'o1',
        cat: 'light',
        img: i1o1,
    },
    i1o3: {
        title: '竹蜻蜓',
        content: '竹蜻蜓是利用運動感應模塊可以提供運動相關的數據，並結合電機模塊來控制竹蜻蜓的轉動，小朋友只要動動腦袋，竹蜻蜓就可以自己轉動起來啦！',
        fn: '該模組可以通過運用感應模塊提供運動姿態相關的數據，並結合電機及來控制物體的運動',
        inputModule: 'i1',
        outputModule: 'o3',
        cat: 'power',
        img:i1o3,
        // img: VideoArea,
    },
    i2o0: {
        title: '迷你電子琴製作',
        content: '紙電子琴是由硬卡紙裁剪，摺疊並粘合而成的迷你電子琴。在琴鍵位置上放置有導電膠布，可以由手指觸摸直接控制它發聲。電子琴的音色可以改變，小朋友可以直接用它來演奏樂曲。',
        fn: '通過觸摸行為來發出樂器聲音 ',
        inputModule: 'i2',
        outputModule: 'o0',
        cat: 'music',
				video:'https://v.qq.com/iframe/player.html?vid=b0524gha0um&tiny=0&auto=0',
        // video: 'https://player.vimeo.com/video/242438607?title=0&portrait=0',
        // img:i2o0,
        img: VideoArea,
    },
    i2o1: {
        title: '觸摸式燈光雪人',
        content: '燈光雪人可以由卡紙、泡沫、透光塑料等多種可透光材料裁剪粘合而成，或者通過3D打印技術打印出燈光雪人的外形。在內部放入組合模塊一。觸摸感應模塊加LED燈模塊的組合，使我們能夠通過觸摸導電膠帶或其他任意導電材料便可以調節燈光的顏色及大小，產生絢麗的燈光效果，由此製作出一個十分好看的燈光雪人。',
        fn: '利用該模組可以實現通過觸摸的方式來控制燈光的顏色和大小的功能',
        inputModule: 'i2',
        outputModule: 'o1',
        cat: 'light',
				video:'https://v.qq.com/iframe/player.html?vid=z0524ouqt65&tiny=0&auto=0',
        // video: 'https://player.vimeo.com/video/232430618?title=0&portrait=0',
        img: i2o1,
    },
    i2o3: {
        title: '觸摸感應旋轉木馬',
        content: '利用一些簡單的材料活着3D打印技術製作出旋轉木馬的外形，結合一些導電材料就可以通過簡單的觸摸動作使旋轉木馬運轉起來。',
        fn: '利用該模塊可以實現通過觸摸的方式來控制裝置的運動',
        inputModule: 'i2',
        outputModule: 'o3',
        cat: 'power',
        img: i2o3,
    },
    i3o1: {
        title: '會跳舞的燈',
        content: '聲控燈可以由卡紙、泡沫、透光塑料等多種可透光材料裁剪粘合而成，內部放入模塊組合四。結合音頻分析模塊，我們可以利用音樂來改變和控制燈光的顏色及大小，得到非常炫目的“會跳舞的燈光。',
        fn: '使用該模組可以通過輸入音頻文件的方式來控制燈光',
        inputModule: 'i3',
        outputModule: 'o1',
        cat: 'light',
        img: i3o1,
    },
    i3o3: {
        title: '音樂摩天輪',
        content: '利用卡紙、木棒等簡單的材料或者結合3D打印技術我們可以製作一個小小的摩天輪裝置，通過與模組的結合，最終能夠實現在播放音樂的同時，使摩天輪持續轉動起來，當音樂停止的時候摩天輪便會停止轉動。',
        fn: '該模組可以將音頻文件轉化為數據，並以此來控制裝置的運動',
        inputModule: 'i3',
        outputModule: 'o3',
        cat: 'power',
        // img:i3o3,
        img: VideoArea,
    },
    i4o0: {
        title: '彩虹琴',
        content: '彩虹琴是一種通過用不同的顏色觸發不同音調的電子琴。其原理是通過顏色分析模塊的色彩識別功能，分辨出你手中任意物體的顏色，並通過編程將其這些顏色與特定的音調對應起來，如此便可完成一個十分有趣的彩虹琴啦！',
        fn: '利用該模組可以通過分辨各種不同的顏色來控制音樂的播放',
        inputModule: 'i1',
        outputModule: 'o0',
        cat: 'music',
        img:i4o0,
    },
    i4o1: {
        title: '辨色式燈光雪人',
        content: '燈光雪人可以由卡紙、泡沫、透光塑料等多種可透光材料裁剪粘合而成或者通過3D打印技術打印出燈光雪人的外形，內部放入我們的模塊組合二。通過顏色分析模塊的色彩識別功能，可以使雪人變換成你手中任意物體的顏色。',
        fn: '通過該模組可以使燈光的顏色變成你手中任意物體的顏色',
        inputModule: 'i4',
        outputModule: 'o1',
        cat: 'light',
        img: i4o1,
    },
    i5o0: {
        title: '電子門迎裝置',
        content: '電子門迎裝置是利用環境監測模塊的外接紅外線功能，識別運動的物體並通過音樂模塊發出聲音。通常可以放置在便利店門口，每當有顧客進出的時候便會發出“歡迎光臨”等聲音。',
        fn: '利用該模組可以通過感應聲音、温度、濕度、光照等外部環境條件的變化以及外接紅外線、超聲波等因素來控制音樂的播放 ',
        inputModule: 'i5',
        outputModule: 'o0',
        cat: 'music',
        img:i5o0,
    },
    i5o1: {
        title: '聲控燈',
        content: '聲控燈可以由卡紙、泡沫、透光塑料等多種可透光材料裁剪粘合而成，內部放入模塊組合三。結合環境檢測模塊，我們可以利用聲音來改變和控制燈光的顏色及大小。',
        fn: '利用該模組可以通過感應聲音、温度、濕度、光照等外部環境條件的變化以及外接紅外線、超聲波等因素來控制燈光',
        inputModule: 'i5',
        outputModule: 'o1',
        cat: 'light',
        img: i5o1,
    },
    i5o2: {
        title: '實時環境監測',
        content: '通過一個簡易的裝置實時監測周圍環境的温度／濕度／光照等環境數據，並且會在OLED屏幕顯示出來。',
        fn: '利用該模組可以通過感應聲音、温度、濕度、光照等外部環境的信息並生成相應的數據 ',
        inputModule: 'i5',
        outputModule: 'o2',
        cat: 'light',
				video:'https://v.qq.com/iframe/player.html?vid=m0524obqgb2&tiny=0&auto=0',
        // video: 'https://player.vimeo.com/video/229513379?title=0&portrait=0',
        img: i5o2,
    },
    i5o3: {
        title: '聲控窗簾',
        content: '聲控窗簾是利用環境檢測模塊對聲音的感應，並且通過電機驅動窗簾自動拉開和聚攏。',
        fn: '利用該模組可以通過感應聲音、温度、濕度、光照等外部環境條件的變化以及外接紅外線、超聲波等因素，結合電機驅動模塊來控制物體的運動',
        inputModule: 'i5',
        outputModule: 'o3',
        cat: 'power',
        img: i5o3,
    },
    i5o4: {
        title: '聞聲花開',
        content: '首先可以利用一些簡單的材料或者結合3D打印技術製作出花朵的外形，利用環境監測模塊對聲音強度值的檢測，結合我們的舵機轉接模塊來對花瓣的運動進行控制，如此便可以製造出通過改變聲音的強度就能控制花朵聚攏和開放的裝置。',
        fn: '利用該模組可以通過感應聲音、温度、濕度、光照等外部環境條件的變化以及外接紅外線、超聲波等因素，結合舵機轉接模塊來控制物體的運動',
        inputModule: 'i5',
        outputModule: 'o4',
        cat: 'power',
        img:i5o4,
    },
		i6o1: {
        title: 'Wi-Fi小枱燈',
        content: 'Wi-Fi小枱燈是利用了包含Wi-Fi功能的模塊，由此對台燈進行遠程操控。',
        fn: '通過Wi-Fi功能對燈光進行遠距離控制',
        inputModule: 'i6',
        outputModule: 'o1',
        cat: 'light',
        img:i6o1,
    },
}

export default sets;
