import React ,{Component} from 'react';
import '../assets/css/footer.css';
import Facebook from '../assets/img/facebook.svg';
import Vimeo from '../assets/img/vimeo.svg';
import cocoroboPatternIcon from '../assets/img/cocoroboPatternIcon.svg';
import {Link} from 'react-router-dom';
import cocorobo_wechat_qrcode from '../assets/img/cocorobo_wechat_qrcode.png'
import cocorobo_facebook_qrcode from '../assets/img/cocorobo_facebook_qrcode.png'



class Footer extends Component{
	render(){
		return(
			<footer>
				<div id='contentInfo'>
					<section>
					<ul className='footerLogo'>
						<span id='cocoroboLogo'><img src={cocoroboPatternIcon} alt='logo' width='30%'/></span>
					</ul>
					</section>
					<section>
					<ul>
						<span>公司</span>
						<li><Link to='/about'>關於我們</Link></li>
						<li><Link to='/contact'>聯繫方式</Link></li>
						<li><a href='http://cocorobo.hk/hr/'>加入我們</a></li>
					</ul>
					</section>
					<section>
					<ul>
						<span>支持</span>
						{/*<li><Link to='/faq/cat1'>FAQ</Link></li> */}
						<li><Link to='/form'>問題反饋</Link></li>
						<li><a href='//help.cocorobo.hk'>使用教程</a></li>
						<li><Link to='/feedback'>授課反饋</Link></li>
					</ul>
					</section>
					<section>
					<ul>
						<span>關注我們</span>
						<li className="news">新聞動態<img src={cocorobo_facebook_qrcode}></img></li>
						<li><a href='https://www.facebook.com/cocorobohk'>Facebook</a></li>
						<li><a href='https://vimeo.com/cocorobo'>Vimeo</a></li>
					</ul>
					</section>
				</div>
				{/*
					<div id='social'>
					<span><img src={Facebook} alt='facebook' width="100%"/></span>
					<span><img src={Vimeo} alt='vimeo' width="100%"/></span>
				</div>
				*/}
				<div className='author'>
					<p>CocoRobo Ltd. 2020 All rights reserved.</p>
				</div>
			</footer>
		)
	}
}

export default Footer;
