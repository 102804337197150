import React from 'react';
import CurriculumKit from '../components/curriculumKit';

import {Route} from 'react-router-dom';

const CurriculumKitPage  = ({match})=>(
  <div>
    <CurriculumKit id={match.params.id} />
  </div>
);

export default CurriculumKitPage;
