import basicKit__light from '../assets/img/basicKit__light.jpg'
import basicKit__IoT from '../assets/img/basicKit__IoT.jpg'
import basicKit__mechanism from '../assets/img/basicKit__mechanism.jpg'
import basicKit__power from '../assets/img/basicKit__power.jpg'
import basicKit__music from '../assets/img/basicKit__music.jpg'
import basicKit__HCI from '../assets/img/basicKit__HCI.jpg'

//更改记录
//Feb 12 2019  Source: Dongting

const basicKit ={
	0:{
		img:basicKit__light,
		name:'light',
		nameCN:'燈光 Basic Kit',
		extension:'擴展包：百變臺燈製作',
		price:200,
	},
	1:{
		img:basicKit__IoT,
		name:'IoT',
		nameCN:'物聯網Basic Kit',
		extension:'擴展包：迷你氣象站製作',
		price:240
	},
	2:{
    img:basicKit__mechanism,
		name:'mechanism',
		nameCN:'機械 Basic Kit',
		extension:'擴展包：激光切割機械臂製作',
		price:180
	},
	3:{
	  img:basicKit__power,
		name:'power',
		nameCN:'動力 Basic Kit',
		extension:'擴展包：激光切割風火輪製作',
		price:180
	},
	4:{
	  img:basicKit__music,
		name:'music',
		nameCN:'音樂Basic Kit',
		extension:'擴展包：尤克里里製作',
		price:220,
	},
	5:{
    img:basicKit__HCI,
		name:'HCI',
		nameCN:'人機交互 Basic Kit',
		extension:'擴展包：遊戲手柄製作',
		price:200
	}
}

export default basicKit;
