import React ,{Component} from 'react';
import TitleMain from '../components/titleMain';
import CardSmallFn from '../components/card_small_function';
import ComingSoon from '../components/comingSoon';
import Slider from '../components/slider';
import '../assets/css/explore.css';
import ShowAllList from '../components/showAllList';
//import image
import project_img from '../assets/img/discoverProject.svg';
import model_img from '../assets/img/discoverModel.svg';
import tutorial_img from '../assets/img/discoverTutorial.svg';
import explore_img_1 from '../assets/img/discover_img_1.jpg';
import TitleSub from '../components/titleSub';

const explore = {
	projects:{
		0:{
			content:'ABCDEFG',
			img:explore_img_1,
			title:'Title',
		},
		1:{
			content:'sdfdd',
			img:explore_img_1,
			title:'ddddd',
		},
		2:{
			content:'ABCDEFG',
			img:explore_img_1,
			title:'Title',
		},
		3:{
			content:'sdfdd',
			img:explore_img_1,
			title:'ddddd',
		}
	},
	models:{
		0:{
			content:'ABCDEJKLJKLDFG',
			img:explore_img_1,
			title:'Title',
		},
		1:{
			content:'HHHHH',
			img:explore_img_1,
			title:'ddddd',
		},
	},
	// tutorials:{
	// 	0:{
	// 		content:'JJJJJJ',
	// 		img:explore_img_1,
	// 		title:'Title',
	// 	},
	// 	1:{
	// 		content:'KKKKK',
	// 		img:explore_img_1,
	// 		title:'ddddd',
	// 	},
	// },
}
let currentList = explore.projects;
let projectToRender = Object.keys(currentList).map((x,y)=>
	<CardSmallFn
		key={y}
		content={currentList[x].content}
		img={currentList[x].img}
		title={currentList[x].title}
	/>);
class Explore extends Component{
	constructor(props){
		super(props);
		this.state ={
			projectToRender:projectToRender,
		}
	}
	toggleList(event){
		if(event.target.nodeName === 'IMG'){
			let getName = event.target.id;
			document.querySelectorAll('.toggleActive')[0].className='toggle';
			document.getElementById(getName).className ='toggleActive';
			currentList = explore[getName+'s'];
			projectToRender = Object.keys(currentList).map((x,y)=>
				<CardSmallFn
					key={y}
					content={currentList[x].content}
					img={currentList[x].img}
					title={currentList[x].title}
				/>);
		}
		this.setState({
			projectToRender:projectToRender,
		})
	}
	render(){
		return(
			<div>
				<TitleMain name='探索' />
				{/*<ComingSoon />*/}
				<Slider />
				<div className='explore'>
					<TitleSub name='教程' margin='0'/>
					<ShowAllList />
					<div className='list'>
						{this.state.projectToRender}
					</div>
				</div>
				<div className='explore'>
					<TitleSub name='模型' margin='0'/>
					<ShowAllList />
					<div className='list'>
						{this.state.projectToRender}
					</div>
				</div>
				<div className='explore'>
					<TitleSub name='編程' margin='0'/>
					<ShowAllList />
					<div className='list'>
						{this.state.projectToRender}
					</div>
				</div>
			</div>
		)
	}
}

export default Explore;
