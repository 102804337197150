import React from 'react'
import PropTypes from 'prop-types'
import kit from '../data/kit'

class Video extends React.Component {
  render () {
    return(
      <div>
        <video width='80%' controls='true' src={this.props.src} type='video/mp4' autoPlay='true'>
        </video>
      </div>
    )
  }
}

export default Video;
