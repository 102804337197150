import React from 'react'
import PropTypes from 'prop-types'
//css
import '../assets/css/shoppingCard.css';
import {observer,set} from 'mobx-react';
import module from './modules';


@observer
class ShoppingCard extends React.Component {
  constructor(props){
    super(props);
    this.addToStore = this.addToStore.bind(this);
  }
  addToStore(){
    let name = this.props.title;
    let quantity = typeof this.props.store.order.get(this.props.title) == 'undefined'? 1:this.props.store.order.get(this.props.title)[1]+1;
    let unitPrice = this.props.price;
    this.props.store.addItem(name,quantity,unitPrice);
    if(quantity > 999){
      this.props.store.editItem(name, 999 , unitPrice);
    }
  }
  render () {
    return(
      <div className="ShoppingCard" style={{'backgroundColor':this.props.bgc}}>
        <div className="ShoppingCard__img">
          <img src={this.props.img} alt="shoppingCardimg" width='100%'/>
        </div>
        <div className="ShoppingCard__title">
          <h3 style={{margin:0,color:'#fff'}}>{this.props.title}</h3>
        </div>
        <div className="ShoppingCard__subTitle">
          <p><b>物件清单</b></p>
        </div>
        <div className="ShoppingCard__modules">
          {typeof this.props.modules == 'object'? this.props.modules.map((x,y)=> <div key={y}>{module[x.charAt(0)][x].name}</div>):<div>{this.props.modules}</div>}
        </div>
        <div className="ShoppingCard__others">
          {typeof this.props.lists == 'object'? this.props.lists.map((x,y) => <div key={y}>{x}</div>): ''}
        </div>
        <button onClick={this.addToStore} className='ShoppingCard__add' style={{color:'rgb(255, 127, 102)',border:'0',fontSize:'20px',borderRadius:'5px'}}>添加到訂單</button>
      </div>
    )
  }
}

export default ShoppingCard;
