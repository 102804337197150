import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios';
import TitleMain from './titleMain';
import config from '../config'

class Blockly extends React.Component {
  submitPost(){
    let postContent = document.getElementById('Blockly__input').value;
    let postFilename = document.getElementById('Blockly__filename').value;
    axios.defaults.withCredentials = true;
    axios.post(config.server+'/blockly',{
      xml:postContent,
      filename:postFilename,
    })
    .then(function(res){
      alert(res.data);
    })
    .catch(function(err){
      console.log(err);
    })
  }
  submitGet(){
    let postFilename = document.getElementById('Blockly__filename').value;
    axios.get(config.server+'/blockly/'+postFilename)
    .then(function(res){
      document.getElementById('fileContent').innerHTML = res.data;
    })
    .catch(function(err){
      console.log(err)
    });
  }
  submitGetFileList(){
    axios.defaults.withCredentials = true;
    axios.get(config.server+'/blockly/files')
    .then(function(res){
      document.getElementById('fileList').innerHTML = res.data
    })
    .catch(function(err){
      console.log(err);
    })
  }
  submitUpdate(){
    let postContent = document.getElementById('Blockly__input').value;
    let postFilename = document.getElementById('Blockly__filename').value;
    axios.put(config.server+'/blockly/'+postFilename,{
      xml:postContent,
      filename:postFilename
    })
    .then(function(res){
      alert(res.data);
    })
    .catch(function(err){
      console.log(err);
    })
  }
  submitDelete(){
    let postFilename = document.getElementById('Blockly__filename').value;
    axios.delete(config.server+'/blockly/'+postFilename)
    .then(function(res){
      alert(res.data);
    })
    .catch(function(err){
      console.log(err);
    })
  }
  render () {
    return(
      <div>
        <TitleMain name='Blockly'/>
        <input type='text' id='Blockly__filename'></input>
        <input type='text' id='Blockly__input'></input>
        <button className='Blockly__post' onClick={this.submitPost.bind(this)}>Post</button>
        <button className='Blockly__get' onClick={this.submitGet.bind(this)}>Get</button>
        <button className='Blockly__getFileList' onClick={this.submitGetFileList.bind(this)}>List</button>
        <button className='Blockly__update' onClick={this.submitUpdate.bind(this)}>Update</button>
        <button className='Blockly__delete' onClick={this.submitDelete.bind(this)}>Delete</button>
        <p id='fileList'></p>
        <p id='fileContent'></p>
      </div>
    )
  }
}

export default Blockly;
