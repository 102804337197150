import React from 'react'
import PropTypes from 'prop-types'
import TitleMain from './titleMain'
import '../assets/css/printer.css';
import miniPrinter from '../assets/img/miniPrinter.jpg';

class Printer extends React.Component {
  render () {
    return(
      <div className='Printer'>
        <TitleMain name='3D打印機'/>
        <div className='Printer__body'>
          <img className='Printer__img' src={miniPrinter} width='100%' alt='mini-printer' />
          <div className='Printer__title'>
            <ul className='Printer__list'>
              <li className='Printer-list__item'>型號</li>
              <li className='Printer-list__item'>框架</li>
              <li className='Printer-list__item'>噴頭數量</li>
              <li className='Printer-list__item'>成型尺寸</li>
              <li className='Printer-list__item'>層厚</li>
              <li className='Printer-list__item'>內存卡脫機打印</li>
              <li className='Printer-list__item'>液晶屏</li>
              <li className='Printer-list__item'>噴嘴直徑</li>
              <li className='Printer-list__item'>平台溫度</li>
              <li className='Printer-list__item'>平台製造材質</li>
              <li className='Printer-list__item'>支援材料</li>
              <li className='Printer-list__item'>材料直徑</li>
              <li className='Printer-list__item'>耗材傾向性</li>
              <li className='Printer-list__item'>軟件語言</li>
              <li className='Printer-list__item'>支持文件格式</li>
              <li className='Printer-list__item'>模型支撐功能</li>
              <li className='Printer-list__item'>機器尺寸</li>
              <li className='Printer-list__item'>機器重量</li>
            </ul>
          </div>
          <div className="Printer__content">
            <ul className='Printer__list'>
              <li className='Printer-list__item'>Mini</li>
              <li className='Printer-list__item'>鈑金結構</li>
              <li className='Printer-list__item'>1</li>
              <li className='Printer-list__item'>105*135*120mm</li>
              <li className='Printer-list__item'>0.05-0.2mm</li>
              <li className='Printer-list__item'>SD卡脫機打印／USB聯機打印</li>
              <li className='Printer-list__item'>有</li>
              <li className='Printer-list__item'>標配0.4mm</li>
              <li className='Printer-list__item'>室溫至110度可調</li>
              <li className='Printer-list__item'>鋁板、環氧樹脂</li>
              <li className='Printer-list__item'>PLA／ABS／PETG</li>
              <li className='Printer-list__item'>1.75mm</li>
              <li className='Printer-list__item'>PLA</li>
              <li className='Printer-list__item'>中文／英文</li>
              <li className='Printer-list__item'>STL|OBJ|G-Code</li>
              <li className='Printer-list__item'>生成／不生成可選</li>
              <li className='Printer-list__item'>∮205*331mm</li>
              <li className='Printer-list__item'>7.5KG</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Printer;
