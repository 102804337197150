import React, { Component } from 'react';
import '../assets/css/combination.css';
import '../assets/css/result.css';

//data
import modules from './modules';
import sets from './sets';

//image
import VideoArea from '../assets/img/videoArea.png';
import musicIcon from '../assets/img/icon/theme/music.svg';
import powerIcon from '../assets/img/icon/theme/power.svg';
import lightIcon from '../assets/img/icon/theme/light.svg';
import default_m_icon from '../assets/img/icon/module/defaultM.svg';
import default_i_icon from '../assets/img/icon/module/defaultI.svg';
import default_o_icon from '../assets/img/icon/module/defaultO.svg';
import default_c_icon from '../assets/img/icon/module/defaultC.svg';

//plugin
import scrollToComponent from 'react-scroll-to-component';
import { Button } from '@material-ui/core';


var currentSet = [];

class Combination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moduleSet: modules.i,
            othersSet: modules.o,
            moduleInputId: '',
            moduleOutputId: '',
            title: '',
            content: '',
            fn: '',
            video: '',
            placeholder_video: VideoArea,
            placeholder_content: '請從下方各選取一個模塊',
            cat: '#',
            cat_width: '0',
            cat_height: '0',
            mIcon: default_m_icon,
            iIcon: default_i_icon,
            oIcon: default_o_icon,
            cIcon: default_c_icon,
            disabled: true,
        }
        this.moduleClick = this.moduleClick.bind(this);
        this.reset = this.reset.bind(this);
        this.optionClick = this.optionClick.bind(this);
    }
    moduleClick(event) {
        if (event.target.nodeName === 'IMG') {
            let getName = event.target.getAttribute('alt');
            //if onclick is inputModule
            if (getName.search(/i/g) !== -1 && (typeof currentSet[1] === 'undefined' ? true : modules.o[currentSet[1]].match.indexOf(getName) !== -1)) {
                //init boxShadow

                if (typeof currentSet[0] !== 'undefined') {
                    document.getElementById(currentSet[0]).style.boxShadow = 'none';
                }
                //set icon
                this.setState({
                    iIcon: modules.i[getName].icon,
                })
                //add boxShadow
                document.getElementById(getName).style.boxShadow = '0 0 5px 2px #E4517B';
                currentSet[0] = getName;
                //click inputmodule first
                let arrA = Object.keys(modules.o);
                let matchOutput = modules.i[getName].match;
                matchOutput.map(x => arrA.splice(arrA.indexOf(x), 1));
                var unmatchOutput = {};
                for (var x = 0; x < arrA.length; x++) {
                    unmatchOutput[arrA[x]] = modules.o[arrA[x]]
                }
                Object.keys(modules.o).forEach(x => document.getElementById(x).style.opacity = '1');
                Object.keys(unmatchOutput).forEach(x => document.getElementById(x).style.opacity = '0.3');
                this.returnResult();
            } else if (getName.search(/o/g) !== -1 && (typeof currentSet[0] === 'undefined' ? true : modules.i[currentSet[0]].match.indexOf(getName) !== -1)) {
                //init boxShadow
                if (typeof currentSet[1] !== 'undefined') {
                    document.getElementById(currentSet[1]).style.boxShadow = 'none';
                }
                //set icon
                this.setState({
                    oIcon: modules.o[getName].icon,
                })
                //add boxShadow
                document.getElementById(getName).style.boxShadow = '0 0 5px 2px #56A6DC';
                currentSet[1] = getName;
                //click outputmodule first
                let arrA = Object.keys(modules.i);
                let matchOutput = modules.o[getName].match;
                matchOutput.map(x => arrA.splice(arrA.indexOf(x), 1));
                let unmatchOutput = {};
                for (let x = 0; x < arrA.length; x++) {
                    unmatchOutput[arrA[x]] = modules.i[arrA[x]]
                }
                Object.keys(modules.i).forEach(x => document.getElementById(x).style.opacity = '1');
                Object.keys(unmatchOutput).forEach(x => document.getElementById(x).style.opacity = '0.3');
                this.returnResult();
            }
        }
    }

    checkMoreOption(sets) {
        document.getElementById('title').innerHTML = `<option value='0'>${sets.title}</option>`;
        Object.keys(sets.more).forEach(function(x, y) {
            let addOptionNode = document.createElement('option');
            let addOptionText = document.createTextNode(sets.more[x].title);
            addOptionNode.appendChild(addOptionText);
            addOptionNode.setAttribute('value', y + 1);
            document.getElementById('title').appendChild(addOptionNode);
        });

    }
    optionClick() {
        let optionValue = document.getElementById('title').value;
        let setName = currentSet.join('');
        this.setState({
            content: optionValue === '0' ? sets[setName].content : sets[setName].more[optionValue].content,
            fn: optionValue === '0' ? sets[setName].fn : sets[setName].more[optionValue].fn,
            placeholder_video: optionValue === '0' ? sets[setName].img : sets[setName].more[optionValue].img,
            cat: optionValue === '0' ? this.cat(sets[setName].cat) : this.cat(sets[setName].more[optionValue].cat),
        });
    }
    cat(cat) {
        let theme;
        switch (cat) {
            case 'light':
                theme = lightIcon;
                break;
            case 'music':
                theme = musicIcon;
                break;
            case 'power':
                theme = powerIcon;
                break;
            default:
                theme = '';
        }
        return theme;
    }
    returnResult() {
        if (typeof currentSet[0] !== 'undefined' && typeof currentSet[1] !== 'undefined') {
            //add reset Button
            document.getElementById('reset').style.display = 'block';
            let setName = currentSet.join('');
            //check option existed
            if (typeof sets[setName].more !== 'undefined') {
                this.checkMoreOption(sets[setName]);
                document.querySelectorAll('#content h2')[0].classList.add('option');
                this.setState({
                    disabled: false,
                });
            } else {
                document.getElementById('title').innerHTML = `<option>${sets[setName].title}</option>`;
                document.querySelectorAll('#content h2')[0].classList.remove('option');
                this.setState({
                    disabled: true,
                });
            }
            this.setState({
                title: sets[setName].title,
                content: sets[setName].content,
                fn: sets[setName].fn,
                placeholder_content: '',
                placeholder_video: sets[setName].img,
                video: sets[setName].video,
                cat: this.cat(sets[setName].cat),
                cat_width: '100%',
                cat_height: '100%',
                mIcon: modules.m.m0.icon,
                // iIcon:modules.i[currentSet[0]].icon,
                // oIcon:modules.o[currentSet[1]].icon,
            });
            scrollToComponent(this.result, { offset: 0, align: 'top', duration: 300, ease: 'inCirc' });
            // document.getElementById('guide').style.display='none';
        }
    }
    reset() {
        //init boxShadow
        if (typeof currentSet[0] !== 'undefined' && typeof currentSet[1] !== 'undefined') {
            document.getElementById(currentSet[0]).style.boxShadow = 'none';
            document.getElementById(currentSet[1]).style.boxShadow = 'none';
            document.getElementById('title').innerHTML = '';
            currentSet = [];
            let modules_all = Object.assign({}, modules.i, modules.o);
            Object.keys(modules_all).forEach(x => document.getElementById(x).style.opacity = '1');
            this.setState({
                moduleSet: modules.i,
                othersSet: modules.o,
                moduleInputId: '',
                moduleOutputId: '',
                title: '',
                content: '',
                fn: '',
                video: '',
                placeholder_video: VideoArea,
                placeholder_content: '請從下方各選取一個模塊',
                cat: '',
                cat_width: '0',
                mIcon: default_m_icon,
                iIcon: default_i_icon,
                oIcon: default_o_icon,
                cIcon: default_c_icon,
                disabled: true,
            });
            scrollToComponent(this.selection, { offset: 0, align: 'top', duration: 300, ease: 'inCirc' });
            //remove reset Button
            document.getElementById('reset').style.display = 'none';
            //remove option toggle triangle
            document.querySelectorAll('#content h2')[0].classList.remove('option');
        }
    }
    render() {
        return (
            <div className='wrapper selection'>
						{/*<p id='guide' style={{position:'absolute',left:'50%',top:'200px',transform:'translateX(-50%)',color:'#E4517B'}} className='font'>請選擇I/O模塊</p> */}
						<div id='result' className='font' ref={(section) => { this.result = section; }}>
							<header>結果展示區</header>
							<div id='video'>
								<iframe src={this.state.video} width="100%" height='100%'  frameBorder="0" webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen='true'></iframe>
								<span><img src={this.state.placeholder_video} alt='videoArea' width='100%' height='100%'  /></span>
							</div>
							<div className="theme"><img src={this.state.cat} width={this.state.cat_width} height={this.state.cat_height} alt=''/></div>
							<div className="moduleIcon">
								<div className="mIcon icon"><img src={this.state.mIcon} width='100%' height="100%" alt='mIcon'/></div>
								<div className="iIcon icon"><img src={this.state.iIcon} width='100%' height="100%" alt='iIcon'/></div>
								<div className="oIcon icon"><img src={this.state.oIcon} width='100%' height="100%" alt='oIcon'/></div>
								<div className="cIcon icon"><img src={this.state.cIcon} width='100%' height="100%" alt='cIcon'/></div>
							</div>
							<div id='content'>
								<h2 className=''>
									<select id='title' disabled={this.state.disabled} style={this.state.disabled?{backgroundColor:'transparent'}:{backgroundColor:'#fff'}} onChange={this.optionClick}>
									</select>
								</h2>
								<p className='p1'>{this.state.content}</p>
								<p className='p2'>{this.state.fn}</p>
								<span id='placeholder'>{this.state.placeholder_content}</span>
							</div>
						</div>
						<Button raised color='primary' id='reset' style={{display:'none'}} onClick={this.reset}>
							重置
						</Button>
						<div id='selection' className='selectionContainer' ref={(section) => { this.selection = section; }}>
							<div className='inputModuleBlock block font'>
								<header>輸入模塊</header>
								<ul id='moduleInput' onClick={this.moduleClick}>
									{Object.keys(this.state.moduleSet).map((x)=> (<li key={x} className={x} ><img id={x} alt={x} src={this.state.moduleSet[x].img} width='100%'/><span>{this.state.moduleSet[x].name}</span></li>))}
								</ul>
							</div>
							<div className='outputModuleBlock block font'>
								<header>輸出模塊</header>
								<ul id='moduleOutput' onClick={this.moduleClick}>
									{Object.keys(this.state.othersSet).map((x)=> (<li key={x} className={x}><img id={x} alt={x} src={this.state.othersSet[x].img} width='100%'/><span>{this.state.othersSet[x].name}</span></li>))}
								</ul>
							</div>
						</div>


					</div>
        )
    }
}

export default Combination;