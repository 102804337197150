import React from 'react'
import PropTypes from 'prop-types'
import CardSmallFn from './card_small_function';

//css
import '../assets/css/UGCUserContent.css';

let currentList;

class UGCUserContent extends React.Component {
  render () {
    return(
      <div className='UGCUserContent'>
        {Object.keys(this.props.data[this.props.name]).map((x,y)=>(
          <CardSmallFn
          key={y}
          content={this.props.data[this.props.name][x].content}
          img={this.props.data[this.props.name][x].img}
          title={this.props.data[this.props.name][x].title}
          />))}
      </div>
    )
  }
}

export default UGCUserContent;
