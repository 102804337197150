import React from 'react'
import PropTypes from 'prop-types'
import success from '../assets/img/success.svg'
import '../assets/css/success.css'
import {Redirect} from 'react-router-dom';

class Success extends React.Component {
  constructor(props){
    super(props);
    this.state={
      redirect:false
    }
  }
  componentDidMount(){
    this.interval = setTimeout(()=>(
      this.setState({
        redirect:true
      })
    ),3000)
  }
  render () {
    if(this.state.redirect){
      return <Redirect to='/create'/>;
    }
    return(
      <div>
        <div className="Success">
          <div className="Success__Icon">
            <img src={success} width='100%' className=''/>
          </div>
          <div className="Success__Content">
            {this.props.content}
          </div>
        </div>
      </div>
    )
  }
}

export default Success;
