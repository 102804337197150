import React,{Component} from 'react';
//cocorobolabs
import SliderFullscreen from '../components/sliderFullscreen';
import OneStepSolution from '../components/oneStepSolution';
import PCS from '../components/PCS';
import Partner from '../components/partner';
//mobx
import {observer} from 'mobx-react';

@observer
class Homepage extends Component {
	componentWillMount(){
		this.props.uiStore.isHomepage  = true;
	}
	componentWillUnmount(){
		this.props.uiStore.isHomepage  = false;

	}
	render(){
		return(
			<div>
				<SliderFullscreen />
				<OneStepSolution />
				<PCS />
				<Partner />
			</div>
		)
	}
}

export default Homepage;
