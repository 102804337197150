import React from 'react'
import PropTypes from 'prop-types'
import '../assets/css/UGCHeaderList.css';
import {Link} from 'react-router-dom';


class UGCHeaderList extends React.Component {
  render () {
    return(
      <div className='UGCHeaderList'>
          <img
            src={this.props.img}
            id={this.props.name}
            className={this.props.active === true ? 'UGCHeaderList--active':'UGCHeaderList'}
            alt={this.props.name}
            />
        <span>{this.props.nameCN}</span>
      </div>
    )
  }
}

export default UGCHeaderList;
